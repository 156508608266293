@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&family=DM+Serif+Text&family=Poppins&family=Yellowtail&display=swap');

* {
  -webkit-user-drag: none;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

iframe {
  border: none;
  outline: none;
  background:white;
}

body {
  background-color: #1E2333;
}

body * {
  font-family: 'Poppins', sans-serif;
}

.typical-input {
    background-color: transparent;
    outline: none;
    border: none;
}

.hljs-subst, .hljs-built_in, .hljs-builtin-name, .hljs-symbol, .hljs-selector-id, .hljs-selector-attr, .hljs-selector-pseudo, .hljs-template-tag, .hljs-template-variable, .hljs-addition {
  color: #ffa3fc;
}
.hljs-deletion, .hljs-number, .hljs-quote, .hljs-selector-class, .hljs-selector-id, .hljs-string, .hljs-template-tag, .hljs-type {
  color: #58fff0;
}

*::selection {
  background: #1E2333;
}